import Head from "next/head";
import { Inter } from "next/font/google";
import styles from "@/styles/Home.module.css";
import {
  Button,
  ButtonGroup,
  Grid,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text
} from "@chakra-ui/react";
import Link from "next/link";
import { useEffect, useState } from "react";
import axiosClient from "../utils/axios";
import FullPageLoader from "../components/common/FullPageLoader";
import VehicleStatsChart from "../components/vehicles/reports/stats/VehicleStatsChart";
import { useRouter } from "next/router";
import GarageStats from "../components/vehicles/reports/stats/GarageStats";
import VehicleTypeStats from "../components/vehicles/reports/stats/VehicleTypeStats";

export default function Home() {
  const [stats, setStats] = useState(null);
  const [master, setMaster] = useState("");
  const [city, updateCity] = useState("ALL");

  const router = useRouter();
  const [documentStats, setDocumentStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchStats();
    // fetchDocumentStats();
  }, []);

  const fetchStats = async () => {
    setIsLoading(true);

    try {
      const stats = await axiosClient.get("/stats");
      setStats(stats.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const updateMaster = (stat: string) => {
    if (master === stat) {
      setMaster("");
    } else {
      setMaster(stat);
    }
  };

  if (isLoading) return <FullPageLoader />;

  const renderStat = (label: string, value: string | number, link: string) => {
    return (
      <Stat
        border="1px"
        borderColor="gray.300"
        p="4"
        rounded="md"
        transition={"all .2s ease-in-out"}
        _hover={{
          cursor: "pointer",
          bg: "gray.50",
          borderColor: "gray.400"
        }}
        onClick={() => {
          router.push(link);
        }}
        opacity={master ? (master === label || master === "" ? 1 : 0.5) : 1}
      >
        <StatLabel>{label}</StatLabel>
        <StatNumber>{value}</StatNumber>
      </Stat>
    );
  };

  if (!stats) return null;

  return (
    <Stack gap="4" w="full" pb={8}>
      <Stack textAlign="center" mb="4">
        <Heading size="md" fontWeight="400">
          Welcome to
        </Heading>
        <Heading size="xl" fontWeight="600">
          Advance Mobility Dashboard
        </Heading>
      </Stack>
      <Stack spacing={8}>
        <HStack spacing={4}>
          {renderStat("Direct Drivers", stats.directDrivers, "/drivers")}
          {renderStat("Vendor Drivers", stats.vendorDrivers, "/drivers")}
          {renderStat("Vendors", stats.vendors, "/vendors")}
        </HStack>
        <Stack spacing={2}>
          <VehicleStatsChart city={city} updateCity={updateCity} />
          <VehicleTypeStats city={city} />
        </Stack>
        <GarageStats city={city} />
      </Stack>
    </Stack>
  );
}
